import React from "react"

import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Page = () => (
  <Layout>
    <Seo title="Delte lister" />
    <div className="longtext">
      <h2>Delte lister</h2>

      <StaticImage src="../../images/shared-lists.png" alt="Shared list" />

      <h3>Forutsetninger</h3>
      <p>
        For å kunne bruke delte lister må alle deltakere ha Mente installert
      </p>

      <h3>Del en liste</h3>
      <ol>
        <li>Gå til listen</li>
        <li>Trykk på ... (iOS) eller tittelen (macOS) og velg "Del liste"</li>
        <li>Aktiver "Inviter flere"</li>
        <li>
          Trykk på delingslenken du får opp for å kopiere den, og send den på
          måten du ønsker (SMS/mail/Slack/osv.) til de du vil dele den med
        </li>
      </ol>

      <p>
        <b>OBS!</b> Alle med lenken får full tilgang til listen du deler. Pass
        på hvor du deler lenken og hvem du deler den med.
      </p>

      <h3>Skru av deling</h3>
      <ol>
        <li>Gå til listen</li>
        <li>
          Trykk på ... (iOS) eller tittelen (macOS) og velg "Administrer deling"
        </li>
        <li>
          Deaktiver "Inviter flere". Når dette er gjort vil ikke flere kunne bli
          med i listen.
        </li>
        <li>
          Dersom du også vil fjerne noen som allerede har fått tilgang til
          listen, så kan du trykke "Fjern" ved siden av navnene til de du ikke
          ønsker å dele listen med lenger. For å skru av deling helt, trykk
          fjern på alle navnene unntatt ditt eget.
        </li>
      </ol>

      <h3>Deleger oppgaver</h3>
      <p>
        <Link to="/help/delegate-tasks">Se delegering av oppgaver</Link>
      </p>

      <h3>Eksempler på lister å dele</h3>
      <ul>
        <li>
          <b>Handlelisten</b>. Tips: legg inn ting med en gang du går tom/kaster
          emballasjen, så har dere alltid en oppdatert handleliste til nestemann
          er på butikken.
        </li>
        <li>
          <b>Hjemmet</b>. Og andre ting dere har felles ansvar for (barn, bil,
          hund++), så kan dere holde styr på alt som må fikses, ryddes, kjøpes
          inn og ordnes.
        </li>
        <li>
          <b>Meg + Person</b>. Lag en delt liste med en kollega/venn/søster, så
          kan dere delegere ting til hverandre og følge opp ting som er viktig
          for dere.
        </li>
        <li>
          <b>Prosjekt X</b>. Alt som er felles prosjekter med andre på
          jobb/skole/hjemme. Strukturer listen inn i seksjoner og sett hvem som
          er ansvarlig for ulike oppgaver.
        </li>
      </ul>

      <h3>Deling av underlister og seksjoner</h3>
      <ul>
        <li>
          <Link to="/help/list-foundations">Seksjoner</Link> deles automatisk
          sammen med listen de hører til.
        </li>
        <li>
          <Link to="/help/list-foundations">Underlister</Link> deles ikke
          automatisk, men om du vil kan du gjenta stegene under "Del en liste"
          om du vil dele disse også{" "}
        </li>
      </ul>
    </div>
  </Layout>
)

export default Page
